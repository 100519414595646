import { css, styled } from './theme-provider';

export const MiniButton = styled.button<{ readonly $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: ${theme.size.x3};
    height: ${theme.size.x3};
    color: ${$isActive ? theme.color.brand_yarmill_56 : theme.color.neutral_neutral_64};
    border: none;
    cursor: pointer;
    background-color: transparent;

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
      pointer-events: none;
    }

    &:hover {
      color: ${$isActive ? theme.color.brand_yarmill_16 : theme.color.neutral_neutral};
    }

    &:focus-visible {
      outline: none;
      color: ${theme.color.brand_yarmill_56};
    }
  `};
`;
