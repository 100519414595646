import { MouseEventHandler, ReactElement } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { css, styled } from './theme-provider';

const ButtonWrapper = styled.div<{ readonly $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  ${({ theme, $isActive }) => css`
    border-top-left-radius: ${theme.borderRadius.x1};
    border-bottom-left-radius: ${theme.borderRadius.x1};
    margin-left: ${theme.size.x15};
    width: calc(100% - ${theme.size.x15});
    background-color: ${$isActive ? theme.color.background_background_03 : 'transparent'};
  `};
`;

const InputBorderRadius = styled.div`
  position: absolute;
  user-select: none;
  pointer-events: none;
  ${({ theme }) => css`
    right: 0;
    width: ${theme.size.x1};
    height: ${theme.size.x1};
    background-color: ${theme.color.background_background_03};

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: ${theme.size.x1};
      height: ${theme.size.x1};
      background-color: ${theme.color.background_background_04};
    }
  `};
`;

const TopBorderRadius = styled(InputBorderRadius)`
  ${({ theme }) => css`
    top: -${theme.size.x1};
    &:before {
      border-bottom-right-radius: ${theme.size.x1};
    }
  `};
`;

const BottomBorderRadius = styled(InputBorderRadius)`
  ${({ theme }) => css`
    bottom: -${theme.size.x1};
    &:before {
      border-top-right-radius: ${theme.size.x1};
    }
  `};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: relative;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x1};
    padding: ${theme.size.x075} ${theme.size.x125};
    color: ${theme.color.neutral_neutral};
    width: ${theme.size.x5};
    background-color: transparent;

    svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }

    :hover {
      color: ${({ theme }) => theme.color.neutral_neutral_dark};
    }
  `};
`;

interface TotemButtonProps {
  readonly icon: ReactElement;
  readonly label?: string;
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly to?: LinkProps['to'];
  readonly isActive?: boolean;
}

export function TotemButton({
  icon,
  label,
  onClick,
  to,
  isActive = false,
}: TotemButtonProps): ReactElement {
  return (
    <ButtonWrapper $isActive={isActive}>
      {isActive && <TopBorderRadius />}
      {to ? (
        <Button as={Link} to={to} aria-label={label}>
          {icon}
        </Button>
      ) : (
        <Button aria-label={label} onClick={onClick} type="button">
          {icon}
        </Button>
      )}
      {isActive && <BottomBorderRadius />}
    </ButtonWrapper>
  );
}
