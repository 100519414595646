import { PropsWithChildren, ReactElement, Ref } from 'react';
import { css, styled } from './theme-provider';

const Layout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  flex-grow: 1;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x2};
    background-color: ${theme.color.neutral_white};
  `};
`;

const ToolbarWrapper = styled.div`
  flex: 0;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
`;

type DrawerProps = PropsWithChildren<{
  readonly header: ReactElement;
  readonly footer: ReactElement;
  readonly contentRef?: Ref<HTMLDivElement>;
}>;
export function Canvas({ header, children, footer, contentRef }: DrawerProps) {
  return (
    <Layout>
      <ToolbarWrapper>{header}</ToolbarWrapper>
      <ContentWrapper ref={contentRef}>{children}</ContentWrapper>
      <ToolbarWrapper>{footer}</ToolbarWrapper>
    </Layout>
  );
}
