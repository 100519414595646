import {
  Dispatch,
  HTMLProps,
  KeyboardEvent,
  SetStateAction,
  createContext,
} from 'react';

interface DropdownContextValue<V> {
  readonly getItemProps: (
    userProps?: HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  readonly activeIndex: number | null;
  readonly setActiveIndex: Dispatch<SetStateAction<number | null>>;
  readonly setHasFocusInside: Dispatch<SetStateAction<boolean>>;
  readonly setIsOpened: Dispatch<SetStateAction<boolean>>;
  readonly isOpen: boolean;
  readonly selectedValue: V | undefined;
  readonly handleSelect: (
    index: number | null,
    value: V,
    isCheckboxClick?: boolean
  ) => void;
  readonly multiSelect: boolean;
  readonly setFilterValue: Dispatch<SetStateAction<string>>;
  readonly onInputKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  readonly filterValue: string;
  readonly id?: string;
  readonly useOptionReferenceSize?: boolean;
  readonly overlayReferenceButton?: boolean;
}

export const DropdownContext = createContext<DropdownContextValue<any>>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
  selectedValue: undefined,
  handleSelect: () => ({}),
  multiSelect: false,
  setFilterValue: () => {},
  onInputKeyDown: () => {},
  filterValue: '',
  useOptionReferenceSize: false,
  setIsOpened: () => {},
  overlayReferenceButton: false,
});
