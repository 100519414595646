import { ReactElement } from 'react';
import { Text } from './text';
import { css, styled } from './theme-provider';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x05};
    background-color: ${theme.color.neutral_neutral_dark};
    color: ${theme.color.neutral_neutral_64};
    height: ${theme.size.x2};
    min-width: ${theme.size.x2};

    svg {
      width: ${theme.size.x1};
      height: ${theme.size.x1};
    }
  `}
`;

export const KeysCombination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    column-gap: ${theme.size.x025};
  `}
`;

interface KeyProps {
  readonly k: string | ReactElement;
}

export function Key({ k }: KeyProps): ReactElement {
  return (
    <Layout>
      {typeof k === 'string' ? <Text appearance="_10M">{k}</Text> : k}
    </Layout>
  );
}
