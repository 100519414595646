import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import { styled } from '../theme-provider';
import { FullscreenLayer } from './fullscreen-layer';
import { LayerContext } from './layer-context';
import { Overlay } from './overlay';

type LayerProviderProps = PropsWithChildren<{
  readonly content: (close: () => void) => ReactElement;
  readonly modal?: boolean;
  readonly controlledState?: [boolean, Dispatch<SetStateAction<boolean>>];
  readonly zIndex?: number;
  readonly 'aria-label'?: string;
}>;

const LayerButton = styled.button`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  padding: 0;
  margin: 0;
  border: none;
  background: none;

  :focus-visible {
    outline: none;
  }
`;

export const LayerProvider = forwardRef<HTMLButtonElement, LayerProviderProps>(
  function LayerProvider(
    { children, content, controlledState, zIndex, 'aria-label': ariaLabel },
    passedRef
  ) {
    const uncontrolledState = useState<boolean>(false);
    const [isLayerOpened, setIsLayerOpened] =
      controlledState ?? uncontrolledState;

    const { refs, context } = useFloating({
      open: isLayerOpened,
      onOpenChange: setIsLayerOpened,
    });

    const role = useRole(context, {
      role: 'dialog',
    });
    const click = useClick(context);

    const { isMounted, styles: layerTransitionStyles } = useTransitionStyles(
      context,
      {
        initial: {
          opacity: 0,
          transform: 'scale(0.95)',
        },
        close: { opacity: 0 },
        duration: 200,
      }
    );
    const { styles: overlayTransitionStyles } = useTransitionStyles(context, {
      initial: {
        background: 'rgba(47, 46, 69, 0)',
      },
      open: {
        background: 'rgba(47, 46, 69, 1)',
      },
      close: {
        background: 'rgba(47, 46, 69, 0)',
        transitionTimingFunction: `cubic-bezier(0, 0, 0.08, 1.02)`,
      },
      duration: 200,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      click,
      role,
    ]);

    const contextValue = useMemo(
      () => ({ closeLayer: () => setIsLayerOpened(false) }),
      [setIsLayerOpened]
    );

    const buttonRef = useMergeRefs([refs.setReference, passedRef]);

    return (
      <>
        <LayerButton
          ref={buttonRef}
          {...getReferenceProps()}
          type="button"
          aria-label={ariaLabel}
        >
          {children}
        </LayerButton>
        {isMounted && (
          <FloatingPortal>
            <Overlay style={overlayTransitionStyles} $zIndex={zIndex} />
            <FloatingFocusManager context={context}>
              <FullscreenLayer
                {...getFloatingProps()}
                style={layerTransitionStyles}
                ref={refs.setFloating}
                $zIndex={zIndex}
              >
                <LayerContext.Provider value={contextValue}>
                  {content(() => setIsLayerOpened(false))}
                </LayerContext.Provider>
              </FullscreenLayer>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </>
    );
  }
);
