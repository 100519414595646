import { Text } from './text';
import { TextAppearance, styled } from './theme-provider';

const Layout = styled.span`
  position: relative;
  text-align: center;
`;

const Dot = styled(Text)`
  display: inline-block;
  border-radius: 50%;
  margin-right: 1px;
  animation: wave 1.5s linear infinite;

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }

  @keyframes wave {
    0%, 20%, 100% {
      transform: initial;
    }

    10% {
      transform: translateY(-15%);
    }
  }
`;

interface LoadingDotsProps {
  readonly appearance: TextAppearance;
}

export function LoadingDots({ appearance }: LoadingDotsProps) {
  return (
    <Layout>
      <Dot appearance={appearance}>.</Dot>
      <Dot appearance={appearance}>.</Dot>
      <Dot appearance={appearance}>.</Dot>
    </Layout>
  );
}
