import { useRootStore } from '@yarmill/utils';
import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { Logo } from '../app/logo';
import { Navigation } from './navigation';
import { UserSection } from './user-section';
import { TopBar } from '@yarmill/components';
import { LazyModule } from '../modules/lazy-module';

function InternalHeader(): JSX.Element {
  const rootStore = useRootStore();
  const isReady = rootStore.isReady;

  return (
    <TopBar
      logo={<Logo />}
      rightSection={
        isReady ? (
          <>
            <LazyModule moduleCode="yollanda" />
            <UserSection />
          </>
        ) : undefined
      }
    >
      {isReady && <Navigation />}
    </TopBar>
  );
}

export const Header = memo(observer(InternalHeader));
