import { RefObject, useEffect } from 'react';
import debounce from 'lodash.debounce';

export function useTextAreaAutosize(
  textareaRef: RefObject<HTMLTextAreaElement>,
  enabled = true,
  rows?: number
) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const area = textareaRef.current;
    function heightListener() {
      const area = textareaRef.current;

      if (area) {
        let prevRows: number | null = null;
        if (rows) {
          prevRows = area.rows;
          area.rows = rows;
        }
        area.style.height = 'auto';
        area.style.height = area.scrollHeight + 'px';
        if (prevRows) {
          area.rows = prevRows;
        }
      }
    }
    heightListener();

    area?.addEventListener('input', heightListener);

    const resizeObserver = new ResizeObserver(debounce(heightListener, 50));

    if (area) {
      resizeObserver.observe(area);
    }

    return () => {
      area?.removeEventListener('input', heightListener);
      resizeObserver.disconnect();
    };
  }, [textareaRef, enabled, rows]);
}
