import { Search } from '@yarmill/icon-library';
import { ChangeEvent, useCallback, useContext } from 'react';
import { TextInput } from '../text-input';
import { styled } from '../theme-provider';
import { DropdownContext } from './dropdown-context';

const SearchFieldWrapper = styled.div`
  padding-bottom: ${({ theme }) => `${theme.size.x1}`};
`;

interface SearchFieldProps {
  readonly placeholder?: string;
}
export function SearchField({ placeholder }: SearchFieldProps) {
  const dropdownContext = useContext(DropdownContext);
  const { setFilterValue, setActiveIndex, onInputKeyDown, filterValue, id } =
    dropdownContext;

  const handleFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setFilterValue(e.target.value);
      setActiveIndex(0);
    },
    [setFilterValue, setActiveIndex]
  );

  return (
    <SearchFieldWrapper>
      <TextInput
        autoComplete="off"
        autoFocus
        icon={<Search />}
        id={`search-${id}`}
        name="search"
        onChange={handleFilter}
        onKeyDown={onInputKeyDown}
        spellCheck={false}
        value={filterValue}
        placeholder={placeholder}
      />
    </SearchFieldWrapper>
  );
}
