import { UserGroupId, UserId } from '@yarmill/types';
import { CancelTokenSource } from 'axios';
import { useCallback } from 'react';
import { isTouchDevice } from '../utils';
import { useConfig } from './use-config';
import { useYollandaService } from './use-yollanda-service';

export function useFetchAutoCompletion(
  athleteId: UserId | null,
  groupId: UserGroupId | null
) {
  const yollandaService = useYollandaService();
  const isCopilotAvailable = useConfig('yollandaCopilotEnabled');

  const fetchAutoCompletion = useCallback(
    async (query: string, cancelToken?: CancelTokenSource) =>
      (await yollandaService?.suggestTextCompletion(
        query,
        athleteId,
        groupId,
        cancelToken
      )) ?? '',
    [yollandaService, athleteId, groupId]
  );

  return isCopilotAvailable && yollandaService && !isTouchDevice()
    ? fetchAutoCompletion
    : undefined;
}
