import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { DropdownOption } from './dropdown-provider';

export function useInputKeydownHandler<V>(
  activeIndex: number | null,
  options: DropdownOption<V>[],
  isNested: boolean,
  isMultiSelect: boolean | undefined,
  handleSelect: (
    activeIndex: number,
    value: V,
    isMultiSelectClick?: boolean
  ) => void
): [(e: KeyboardEvent<HTMLInputElement>) => void, boolean] {
  const [disableFocusOnHover, setDisableFocusOnHover] =
    useState<boolean>(false);
  const hasSubcategories = useMemo(
    () => Boolean(options.find(o => o.children?.length)),
    [options]
  );

  return [
    useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (!disableFocusOnHover) {
          setDisableFocusOnHover(true);
          let mouseX: number | null = null;
          let mouseY: number | null = null;
          function handleMouseMove(e: MouseEvent) {
            if (mouseX === null && mouseY === null) {
              mouseX = e.clientX;
              mouseY = e.clientY;
            } else {
              if (mouseX !== e.clientX || mouseY !== e.clientY) {
                setDisableFocusOnHover(false);
                window.removeEventListener('mousemove', handleMouseMove);
              }
            }
          }
          window.addEventListener('mousemove', handleMouseMove);
        }

        if (e.key === 'Enter' && activeIndex != null && options[activeIndex]) {
          const value = options[activeIndex].value;
          if (
            ((hasSubcategories && isNested) || !hasSubcategories) &&
            value !== undefined
          ) {
            const keepOpen = isMultiSelect && !e.metaKey && !e.ctrlKey;
            handleSelect(activeIndex, value, keepOpen);
          }
        } else if (e.key === 'Escape') {
          e.preventDefault();
        }
      },
      [
        activeIndex,
        hasSubcategories,
        handleSelect,
        isMultiSelect,
        isNested,
        options,
        disableFocusOnHover,
      ]
    ),
    disableFocusOnHover,
  ];
}
