import { ThemeDefinition } from './theme-definition';

export const master: ThemeDefinition = {
  name: 'master',
  borderRadius: {
    x2: '16px',
    x25: '20px',
    x15: '12px',
    x1: '8px',
    x075: '6px',
    x05: '4px',
    x025: '2px',
  },
  boxShadow: {
    bs1: '0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    bs2: '0px 4px 8px 0px rgba(34, 35, 50, 0.16)',
    bs3: '0 0 0 1px #2161dc',
    bs4: '0px 4px 128px 0px rgba(0, 0, 0, 0.24), 0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  },
  color: {
    /* Color styles */
    neutral_black: '#1F1E31',
    neutral_neutral_darker: '#2F2E45',
    neutral_neutral_dark: '#4A4962',
    neutral_neutral: '#6F6E8C',
    neutral_neutral_64: '#A3A2B5',
    neutral_neutral_36: '#CBCBD5',
    neutral_neutral_28: '#D7D7DF',
    neutral_neutral_20: '#E2E2E8',
    neutral_neutral_8: '#F4F4F6',
    neutral_neutral_5: '#F8F8F8',
    neutral_neutral_2: '#FCFBFB',
    neutral_white: '#FFFFFF',
    background_background_01: '#FCFBFB',
    background_background_02: '#F8F8F8',
    background_background_03: '#F0F0F0',
    background_background_04: '#E7E7E7',
    background_background_05: '#DDDDDD',
    brand_yarmill_dark: '#3B02D6',
    brand_yarmill: '#513FF8',
    brand_yarmill_56: '#9E93FB',
    brand_yarmill_35: '#C3BCFD',
    brand_yarmill_20: '#DCD9FE',
    brand_yarmill_16: '#E3E0FE',
    brand_yarmill_10: '#EDEBFE',
    brand_yarmill_6: '#F5F4FF',
    other_red_dark: '#EC1A03',
    other_red: '#FD513F',
    other_red_8: '#FFF1F0',
    other_red_16: '#FEE3E0',
    other_red_24: '#FFD5D1',
    other_red_40: '#FEB9B2',
    other_green_dark: '#295C3C',
    other_green: '#328052',
    other_green_8: '#EFF5F2',
    other_green_16: '#B6DAC1',
    other_green_24: '#67A27B',
    other_green_40: '#389C63',
    other_tangerine_dark: '#EB3C32',
    other_tangerine: '#FF574D',
    other_tangerine_8: '#FFF2F1',
    other_tangerine_24: '#FFD7D4',
    other_tangerine_40: '#FFBCB8',
    other_orangine_dark: '#EA4905',
    other_orangine: '#FC4C02',
    other_orangine_8: '#FFF1EB',
    other_orangine_24: '#FED4C2',
    other_orangine_40: '#FEB79A',
    other_sand_dark: '#EAA318',
    other_sand: '#FFA900',
    other_sand_8: '#FFF9EB',
    other_sand_24: '#FFEBC2',
    other_sand_40: '#FFDD99',
    other_blue_dark: '#40ACCE',
    other_blue: '#5BC3E4',
    other_blue_8: '#F2FBFD',
    other_blue_24: '#D8F1F9',
    other_blue_40: '#BDE7F4',
    other_pink_dark: '#C54D66',
    other_pink: '#EB5C7A',
    other_pink_8: '#FEF2F5',
    other_pink_24: '#FAD8DF',
    other_pink_40: '#F7BECA',
    other_blush_dark: '#D85374',
    other_blush: '#FC7B9B',
    other_blush_8: '#FFF5F7',
    other_blush_24: '#FEE0E7',
    other_blush_40: '#FECAD7',
    other_yellow_dark: '#C39B0F',
    other_yellow: '#FEC601',
    other_yellow_8: '#FFFBEB',
    other_yellow_24: '#FFF2C2',
    other_yellow_40: '#FFE899',
    other_steel_dark: '#9AB9C5',
    other_steel: '#B2D3E0',
    other_steel_8: '#F9FCFD',
    other_steel_24: '#EDF5F8',
    other_steel_40: '#E0EDF3',
    other_moss_dark: '#103B2E',
    other_moss: '#1C5746',
    other_moss_8: '#EDF2F1',
    other_moss_24: '#C9D7D3',
    other_moss_40: '#A4BCB5',
    other_brown_dark: '#755B42',
    other_brown: '#C7925C',
    other_brown_8: '#FBF7F2',
    other_brown_24: '#F2E5D8',
    other_brown_40: '#E9D3BE',
    other_acid_green_dark: '#36A058',
    other_acid_green: '#53E883',
    other_acid_green_8: '#F2FEF5',
    other_acid_green_24: '#D6FAE1',
    other_acid_green_40: '#BAF6CD',
    other_mint_dark: '#079FA2',
    other_mint: '#8BE3E4',
    other_mint_8: '#F6FDFD',
    other_mint_24: '#E3F8F9',
    other_mint_40: '#D1F4F4',
    other_pumpkin_dark: '#BB5A0D',
    other_pumpkin: '#F26B00',
    other_pumpkin_8: '#FEF4EB',
    other_pumpkin_24: '#FCDCC2',
    other_pumpkin_40: '#FAC499',
    other_plum_dark: '#563B5D',
    other_plum: '#92679D',
    other_plum_8: '#F7F3F8',
    other_plum_24: '#E5DBE8',
    other_plum_40: '#D3C2D8',
    other_swamp_dark: '#5F5C3D',
    other_swamp: '#988F2A',
    other_swamp_8: '#F7F6EE',
    other_swamp_24: '#E6E4CC',
    other_swamp_40: '#D6D2AA',
    other_cloud_dark: '#57709C',
    other_cloud: '#99B2DD',
    other_cloud_8: '#F7F9FD',
    other_cloud_24: '#E7EDF7',
    other_cloud_40: '#D6E0F1',
    other_frog_dark: '#77952B',
    other_frog: '#B2E041',
    other_frog_8: '#F9FDF0',
    other_frog_24: '#EDF8D2',
    other_frog_40: '#E0F2B3',
    other_orangine_mild_dark: '#AD6749',
    other_orangine_mild: '#FF9B71',
    other_orangine_mild_8: '#FFF7F4',
    other_orangine_mild_24: '#FFE7DD',
    other_orangine_mild_40: '#FFD7C6',
  },
  size: {
    x0125: '1px',
    x025: '2px',
    x05: '4px',
    x075: '6px',
    x1: '8px',
    x125: '10px',
    x15: '12px',
    x2: '16px',
    x25: '20px',
    x3: '24px',
    x35: '28px',
    x4: '32px',
    x5: '40px',
    x55: '44px',
    x6: '48px',
    x7: '56px',
    x8: '64px',
    x9: '72px',
  },
  text: {
    appearance: {
      _5B: {
        fontSize: '5px',
        fontWeight: '670',
        lineHeight: '7px',
        letterSpacing: 'normal',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _7B: {
        fontSize: '7px',
        fontWeight: '670',
        lineHeight: '10px',
        letterSpacing: 'normal',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _8B: {
        fontSize: '8px',
        fontWeight: '670',
        lineHeight: '11px',
        letterSpacing: 'normal',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _10B: {
        fontSize: '10px',
        fontWeight: '670',
        lineHeight: '14px',
        letterSpacing: '0.2px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _10M: {
        fontSize: '10px',
        fontWeight: '570',
        lineHeight: '14px',
        letterSpacing: '0.2px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _11B: {
        fontSize: '11px',
        fontWeight: '670',
        lineHeight: '16px',
        letterSpacing: '0.2px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _11BText: {
        fontSize: '11px',
        fontWeight: '670',
        lineHeight: '18px',
        letterSpacing: '0.22px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _11M: {
        fontSize: '11px',
        fontWeight: '570',
        lineHeight: '16px',
        letterSpacing: '0.22px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _11MText: {
        fontSize: '11px',
        fontWeight: '570',
        lineHeight: '18px',
        letterSpacing: '0.22px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _10NumB: {
        fontSize: '10px',
        fontWeight: '670',
        lineHeight: '14px',
        letterSpacing: '0.2px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _10NumM: {
        fontSize: '10px',
        fontWeight: '570',
        lineHeight: '14px',
        letterSpacing: '0.2px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _12B: {
        fontSize: '12px',
        fontWeight: '670',
        lineHeight: '18px',
        letterSpacing: '0.24px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _12BText: {
        fontSize: '12px',
        fontWeight: '670',
        lineHeight: '20px',
        letterSpacing: '0.24px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _12M: {
        fontSize: '12px',
        fontWeight: '570',
        lineHeight: '18px',
        letterSpacing: '0.24px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _12MText: {
        fontSize: '12px',
        fontWeight: '570',
        lineHeight: '20px',
        letterSpacing: '0.24px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _12NumB: {
        fontSize: '12px',
        fontWeight: '670',
        lineHeight: '16px',
        letterSpacing: '0.24px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _12NumM: {
        fontSize: '12px',
        fontWeight: '570',
        lineHeight: '16px',
        letterSpacing: '0.24px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _14B: {
        fontSize: '14px',
        fontWeight: '670',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _14BText: {
        fontSize: '14px',
        fontWeight: '670',
        lineHeight: '22px',
        letterSpacing: '0.28px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _14M: {
        fontSize: '14px',
        fontWeight: '570',
        lineHeight: '20px',
        letterSpacing: '0.28px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _14MText: {
        fontSize: '14px',
        fontWeight: '570',
        lineHeight: '22px',
        letterSpacing: '0.28px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _14NumB: {
        fontSize: '14px',
        fontWeight: '670',
        lineHeight: '18px',
        letterSpacing: '0.14px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _14NumM: {
        fontSize: '14px',
        fontWeight: '570',
        lineHeight: '18px',
        letterSpacing: '0.14px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _16B: {
        fontSize: '16px',
        fontWeight: '670',
        lineHeight: '22px',
        letterSpacing: '0.16px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _16BText: {
        fontSize: '16px',
        fontWeight: '670',
        lineHeight: '24px',
        letterSpacing: '0.32px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _16M: {
        fontSize: '16px',
        fontWeight: '570',
        lineHeight: '22px',
        letterSpacing: '0.32px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _16MText: {
        fontSize: '16px',
        fontWeight: '570',
        lineHeight: '24px',
        letterSpacing: '0.32px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _16NumB: {
        fontSize: '16px',
        fontWeight: '670',
        lineHeight: '20px',
        letterSpacing: '0.16px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _16NumM: {
        fontSize: '16px',
        fontWeight: '570',
        lineHeight: '20px',
        letterSpacing: '0.16px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums tabular-nums',
      },
      _18M: {
        fontSize: '18px',
        fontWeight: '570',
        lineHeight: '20px',
        letterSpacing: '0.36px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _24B: {
        fontSize: '24px',
        fontWeight: '670',
        lineHeight: '28px',
        letterSpacing: '0.48px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _24M: {
        fontSize: '24px',
        fontWeight: '570',
        lineHeight: '28px',
        letterSpacing: '0.48px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _28B: {
        fontSize: '28px',
        fontWeight: '670',
        lineHeight: '32px',
        letterSpacing: '-0.56px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _28M: {
        fontSize: '28px',
        fontWeight: '570',
        lineHeight: '32px',
        letterSpacing: '-0.56px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _40B: {
        fontSize: '40px',
        fontWeight: '670',
        lineHeight: '48px',
        letterSpacing: '-0.8px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
      _40M: {
        fontSize: '40px',
        fontWeight: '570',
        lineHeight: '48px',
        letterSpacing: '-0.8px',
        fontFeatureSettings: `'ss05' on, 'liga' off`,
        fontVariantNumeric: 'lining-nums proportional-nums',
      },
    },
    font: {
      default: 'Haffer, sans-serif',
      emoji: 'Apple Color Emoji, Noto Color Emoji, Android Emoji',
    },
  },
};
