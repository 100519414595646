import { css, styled } from './theme-provider';

export type AvatarSize = 2 | 25 | 3 | 4;

export const Avatar = styled.img<{ readonly $size: AvatarSize }>`
  border-radius: 50%;
  ${({ theme, $size }) => css`
    width: ${theme.size[`x${$size}`]};
    height: ${theme.size[`x${$size}`]};
  `};
`;

export const TeamAvatar = styled(Avatar)`
  ${({ theme, $size }) => css`
    width: ${theme.size[`x${$size}`]};
    height: ${theme.size[`x${$size}`]};
    border-radius: ${$size === 2 ? theme.borderRadius.x05 : $size === 4 ? theme.size.x1 : theme.size.x075};
  `};
`;
