import { ReactElement } from 'react';
import { Text } from './text';
import { css, styled } from './theme-provider';

interface PanelLabelProps {
  readonly icon?: ReactElement;
  readonly text?: string;
}

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x1};
    padding: ${theme.size.x075} ${theme.size.x1};
    column-gap: ${theme.size.x1};
    background-color: ${theme.color.background_background_03};
    color: ${theme.color.neutral_neutral_dark};
  `};
`;

export function PanelLabel({ icon, text }: PanelLabelProps) {
  return (
    <Layout>
      {icon}
      <Text appearance="_12B">{text}</Text>
    </Layout>
  );
}
