import {
  ButtonHTMLAttributes,
  ElementType,
  ReactElement,
  forwardRef,
} from 'react';
import { Text } from './text';
import { ThemeDefinition, css, styled } from './theme-provider';

export enum ButtonAppearance {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Error = 'error',
  Success = 'success',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly icon?: ReactElement;
  readonly appearance?: ButtonAppearance;
  readonly shortcut?: ReactElement;
  readonly as?: ElementType;
  readonly to?: string;
  readonly showSpinner?: boolean;
}

interface StyledButtonProps {
  readonly $appearance: ButtonAppearance;
  readonly $icon: boolean;
  readonly $text: boolean;
}

function getColor(
  theme: ThemeDefinition,
  appearance: ButtonAppearance
): string {
  if (appearance === ButtonAppearance.Error) {
    return theme.color.other_red_8;
  }

  if (appearance === ButtonAppearance.Success) {
    return theme.color.other_green_8;
  }

  if (theme.dark) {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_6;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill_35;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral_28;
    }
  } else {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_6;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral;
    }
  }
}

function getBackgroundColor(
  theme: ThemeDefinition,
  appearance: ButtonAppearance
): string {
  if (appearance === ButtonAppearance.Error) {
    return theme.color.other_red;
  }

  if (appearance === ButtonAppearance.Success) {
    return theme.color.other_green;
  }

  if (theme.dark) {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill;
      case ButtonAppearance.Secondary:
        return theme.color.neutral_neutral_dark;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral_dark;
    }
  } else {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill_10;
      case ButtonAppearance.Tertiary:
        return theme.color.background_background_03;
    }
  }
}

function getBackgroundHoverColor(
  theme: ThemeDefinition,
  appearance: ButtonAppearance
): string {
  if (appearance === ButtonAppearance.Error) {
    return theme.color.other_red_dark;
  }

  if (appearance === ButtonAppearance.Success) {
    return theme.color.other_green_dark;
  }

  if (theme.dark) {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_dark;
      case ButtonAppearance.Secondary:
        return theme.color.neutral_neutral;
      case ButtonAppearance.Tertiary:
        return theme.color.neutral_neutral;
    }
  } else {
    switch (appearance) {
      case ButtonAppearance.Primary:
        return theme.color.brand_yarmill_dark;
      case ButtonAppearance.Secondary:
        return theme.color.brand_yarmill_20;
      case ButtonAppearance.Tertiary:
        return theme.color.background_background_04;
    }
  }
}

const Spinner = styled.div<{ readonly $appearance: ButtonAppearance }>`
  @keyframes rotate-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: 0.8s rotate-spinner infinite linear;
  position: absolute;

  ${({ theme, $appearance }) => css`
    width: ${theme.size.x2};
    height: ${theme.size.x2};
    border-radius: ${theme.borderRadius.x2};
    background: conic-gradient(
      from 90deg at 50% 50%,
      rgba(81, 63, 248, 0) 0deg,
      rgba(227, 224, 254, 0) 0.04deg,
      ${theme.color.brand_yarmill_16} 360deg
    );

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 8px;
      width: 8px;
      background: ${getBackgroundColor(theme, $appearance)};
      border-radius: 12px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 3px;
      width: 3px;
      background: ${theme.color.brand_yarmill_16};
      border-radius: 2px;
    }
  `};
`;

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  flex-shrink: 0;
  transition: background-color 200ms ease;
  text-decoration: none;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  position: relative;


  ${({ theme, $icon, $appearance, $text }) => css`
    column-gap: ${theme.size.x05};
    height: ${theme.size.x4};
    border-radius: ${theme.borderRadius.x1};
    padding: ${$text ? theme.size.x1 : theme.size.x075} ${$icon ? ($text ? theme.size.x1 : theme.size.x125) : theme.size.x15};
    gap: ${theme.size.x05};
    color: ${getColor(theme, $appearance)};
    background-color: ${getBackgroundColor(theme, $appearance)};

    :hover, :focus-visible, button:focus-visible > & {
      background-color: ${getBackgroundHoverColor(theme, $appearance)};
      outline: none;

      ${Spinner}:before {
        background-color: ${getBackgroundHoverColor(theme, $appearance)};
      }
    }

    :disabled {
      cursor: not-allowed;
      color: ${theme.dark ? theme.color.neutral_neutral : theme.color.neutral_neutral_36};
      background-color: ${theme.dark ? theme.color.neutral_neutral_dark : theme.color.background_background_03};

      ${Spinner}:before {
        background-color: ${theme.dark ? theme.color.neutral_neutral_dark : theme.color.background_background_03};
      }
    }
  `};
`;

const ShortcutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${({ theme }) => theme.size.x05};
`;
const IconWrapper = styled.div<{ readonly $hasText: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, $hasText }) => css`
    width: ${$hasText ? theme.size.x2 : theme.size.x25};
    height: ${$hasText ? theme.size.x2 : theme.size.x25};
    svg {
      width: ${$hasText ? theme.size.x2 : theme.size.x25};
      height: ${$hasText ? theme.size.x2 : theme.size.x25};
    }
  `};
`;

const ButtonText = styled(Text)<{ readonly $hidden?: boolean }>`
  display: flex;
  align-items: center;
  line-height: unset;
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      icon,
      children,
      appearance = ButtonAppearance.Primary,
      shortcut,
      showSpinner,
      ...buttonProps
    },
    ref
  ) {
    return (
      <StyledButton
        $appearance={appearance}
        $icon={Boolean(icon)}
        $text={Boolean(children)}
        ref={ref}
        {...buttonProps}
      >
        {icon && <IconWrapper $hasText={Boolean(children)}>{icon}</IconWrapper>}
        {children && (
          <ButtonText appearance="_12B" upperCase $hidden={showSpinner}>
            {children}
          </ButtonText>
        )}
        {showSpinner && <Spinner $appearance={appearance} />}
        {shortcut && <ShortcutWrapper>{shortcut}</ShortcutWrapper>}
      </StyledButton>
    );
  }
);
