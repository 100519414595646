import { TextAppearance, css, styled } from './theme-provider';
import { ElementType } from 'react';

export interface TextProps {
  readonly appearance: TextAppearance;
  readonly whiteSpace?: 'noWrap' | 'pre-wrap';
  readonly align?: 'left' | 'center' | 'right';
  readonly ellipsis?: boolean;
  readonly upperCase?: boolean;
  readonly strikeThrough?: boolean;
  readonly lineClamp?: number;
  readonly capitalize?: boolean;
  readonly wordBreak?: 'break-word' | 'break-all' | 'keep-all';
  readonly as?: ElementType;
}

export function getTextAppearanceStyles(appearance: TextAppearance) {
  try {
    return css`
      ${({
        theme: {
          text: { appearance: appearances },
        },
      }) =>
        appearances[appearance]
          ? css`
         font-size: ${appearances[appearance].fontSize};
        font-weight: ${appearances[appearance].fontWeight};
        line-height: ${appearances[appearance].lineHeight};
        letter-spacing: ${appearances[appearance].letterSpacing};
        font-feature-settings: ${appearances[appearance].fontFeatureSettings};
        font-variant-numeric: ${appearances[appearance].fontVariantNumeric};
      `
          : ''};
  `;
  } catch (e) {
    console.error(e);
    console.log(appearance);
    return '';
  }
}
export const Text = styled.span<TextProps>`
  font-family: ${({ theme }) =>
    `${theme.text.font.default}, ${theme.text.font.emoji}`};
  ${({ appearance }) => getTextAppearanceStyles(appearance)}

  white-space: ${({ whiteSpace }) => whiteSpace};
  text-align: ${({ align }) => align ?? 'inherit'};
  color: inherit;
  padding: 0;
  margin: 0;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `}

  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}

  ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
      hyphens: auto;
    `}

  ${({ strikeThrough }) =>
    strikeThrough &&
    css`
      text-decoration: line-through;
    `}

  ${({ lineClamp }) =>
    lineClamp &&
    css`
      display: -moz-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-box-pack: end;
      -moz-box-orient: vertical;
      line-clamp: ${lineClamp};
      -webkit-line-clamp: ${lineClamp};
      overflow: hidden;
    `}
`;
