import baseStyled, {
  ThemedCssFunction,
  ThemedStyledInterface,
  css as baseCSS,
  keyframes as baseKeyframes,
} from 'styled-components';
import { ThemeDefinition } from './theme-definition';

export * from './theme-definition';
export * from './theme-provider';
export type Color = keyof ThemeDefinition['color'];
export type TextAppearance = keyof ThemeDefinition['text']['appearance'];
export const css = baseCSS as ThemedCssFunction<ThemeDefinition>;
export const styled = baseStyled as ThemedStyledInterface<ThemeDefinition>;
export const keyframes = baseKeyframes;
