import { Dispatch, SetStateAction, useCallback } from 'react';

export function useHandleSelect<V>(
  setIsOpened: Dispatch<SetStateAction<boolean>>,
  passedHandleSelect?: (value: V) => void
) {
  return useCallback(
    (_index: number | null, value: V, keepOpen = false) => {
      if (!keepOpen) {
        setIsOpened(false);
      }
      passedHandleSelect?.(value);
    },
    [passedHandleSelect, setIsOpened]
  );
}
