import { css, styled } from '../theme-provider';

export const FullscreenLayer = styled.div<{ readonly $zIndex?: number }>`
  transition-timing-function: ease-out;
  position: fixed;
  top: 0;
  left: 0;

  ${({ theme, $zIndex }) => css`
    width: calc(100vw - 2 * ${theme.size.x1});
    height: calc(var(--100vh, 100vh) - 2 * ${theme.size.x1});
    margin: ${theme.size.x1};
    ${typeof $zIndex !== 'undefined' ? `z-index: ${$zIndex}` : ''};
  `}
`;
