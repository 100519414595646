import {
  AsyncStatus,
  ModuleDefinitionStore,
  RootStore,
  ModulesRegistryService as iModulesRegistryService,
} from '@yarmill/types';
import { IObservableArray, makeObservable, observable, when } from 'mobx';

export class ModulesRegistryService implements iModulesRegistryService {
  private readonly _rootStore: RootStore;

  @observable
  private _modules: IObservableArray<ModuleDefinitionStore> =
    observable.array();

  @observable
  private _isReady = false;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
    when(
      () =>
        rootStore.configStore.status === AsyncStatus.resolved &&
        rootStore.currentUserStore.status === AsyncStatus.resolved,
      () => this.registerModules()
    );
  }

  get enabledModules(): ModuleDefinitionStore[] {
    return this._modules;
  }

  get isReady() {
    return this._isReady;
  }

  private async registerModules(): Promise<void> {
    const modulesStore = this._rootStore.modulesStore;
    const currentUser = this._rootStore.currentUserStore;

    const lazyModules: Promise<
      ModuleDefinitionStore | ModuleDefinitionStore[]
    >[] = [];

    if (
      (modulesStore.reality && currentUser.isAllowedTo('reality')) ||
      (modulesStore.plan && currentUser.isAllowedTo('plan'))
    ) {
      const trainingDiary = import(
        /* webpackChunkName: "td" */ '@yarmill/training-diary'
      ).then(({ PlanModuleDefinitionStore, RealityModuleDefinitionStore }) => {
        const stores = [];
        if (modulesStore.reality && currentUser.isAllowedTo('reality')) {
          stores.push(new RealityModuleDefinitionStore(this._rootStore));
        }
        if (modulesStore.plan && currentUser.isAllowedTo('plan')) {
          stores.push(new PlanModuleDefinitionStore(this._rootStore));
        }

        return stores;
      });

      lazyModules.push(trainingDiary);
    }

    if (modulesStore.analytics && currentUser.isAllowedTo('analytics')) {
      const analytics = import(
        /* webpackChunkName: "lan" */
        '../../analytics/mobx/legacy-analytics-module-definition-store'
      ).then(
        ({ LegacyAnalyticsModuleDefinitionStore }) =>
          new LegacyAnalyticsModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(analytics);
    }

    if (modulesStore.reporting.length && currentUser.isAllowedTo('reporting')) {
      const reporting = import(
        /* webpackChunkName: "rep" */ '@yarmill/reporting'
      ).then(
        ({ ReportingModuleDefinitionStore }) =>
          new ReportingModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(reporting);
    }

    if (modulesStore.attendance && currentUser.isAllowedTo('attendance')) {
      const attendance = import(
        /* webpackChunkName: "att" */ '@yarmill/attendance'
      ).then(
        ({ AttendanceModuleDefinitionStore }) =>
          new AttendanceModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(attendance);
    }

    if (
      modulesStore.filesOverview &&
      currentUser.isAllowedTo('filesOverview')
    ) {
      const filesOverview = import(
        /* webpackChunkName: "fo" */ '@yarmill/files-overview'
      ).then(
        ({ FilesOverviewModuleDefinitionStore }) =>
          new FilesOverviewModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(filesOverview);
    }

    if (
      modulesStore.seasonEvaluation &&
      currentUser.isAllowedTo('seasonEvaluation')
    ) {
      const seasonEvaluation = import(
        /* webpackChunkName: "se" */ '@yarmill/season-evaluation'
      ).then(
        ({ SeasonEvaluationModuleDefinitionStore }) =>
          new SeasonEvaluationModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(seasonEvaluation);
    }

    if (modulesStore.okr && currentUser.isAllowedTo('okr')) {
      const okrs = import(/* webpackChunkName: "okr" */ '@yarmill/okrs').then(
        ({ OkrsModuleDefinitionStore }) =>
          new OkrsModuleDefinitionStore(this._rootStore)
      );

      lazyModules.push(okrs);
    }

    if (modulesStore.planner && currentUser.isAllowedTo('plan')) {
      const planner = import(
        /* webpackChunkName: "pln" */ '@yarmill/planner'
      ).then(
        ({ PlannerModuleDefinitionStore }) =>
          new PlannerModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(planner);
    }

    if (modulesStore.evidence && currentUser.isAllowedTo('evidence')) {
      const evidence = import(
        /* webpackChunkName: "evd" */ '@yarmill/evidence'
      ).then(
        ({ EvidenceModuleDefinitionStore }) =>
          new EvidenceModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(evidence);
    }

    if (modulesStore.metodej && currentUser.isAllowedTo('metodej')) {
      const metodej = import(
        /* webpackChunkName: "met" */ '@yarmill/metodej'
      ).then(
        ({ MetodejModuleDefinitionStore }) => new MetodejModuleDefinitionStore()
      );
      lazyModules.push(metodej);
    }

    if (modulesStore.yollanda && currentUser.isAllowedTo('yollanda')) {
      const yollanda = import(
        /* webpackChunkName: "yol" */ '@yarmill/yollanda'
      ).then(
        ({ YollandaModuleDefinitionStore }) =>
          new YollandaModuleDefinitionStore(this._rootStore)
      );
      lazyModules.push(yollanda);
    }

    const loadedModules = await Promise.all(lazyModules);

    loadedModules.forEach(loadedModule => {
      if (Array.isArray(loadedModule)) {
        this._modules.push(...loadedModule);
      } else {
        this._modules.push(loadedModule);
      }
    });

    this._isReady = true;
  }
}
