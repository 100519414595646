import { keyframes } from '@yarmill/components';
import { css } from './theme-provider';

const animation = keyframes`
    to {
      background-position: left;
    }
`;

const gradientBase = css`
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animation};
  animation-timing-function: linear;
  position: relative;
`;

export const loadingPlaceholderGradient = css`
  ${gradientBase};

  ${({ theme }) => css`
    background:  linear-gradient(90deg, ${theme.color.background_background_03} 30%, ${theme.color.background_background_04}, ${theme.color.background_background_03} 70%) right / 300% 100%;
  `};
`;

export const loadingPlaceholderGradientDark = css`
  ${gradientBase};

  ${({ theme }) => css`
    background:  linear-gradient(90deg, ${theme.color.background_background_04} 30%, ${theme.color.background_background_03}, ${theme.color.background_background_04} 70%) right / 300% 100%;
  `};
`;

export const loadingPlaceholderGradientAi = css`
  ${gradientBase};

  ${({ theme }) => css`
    background:  linear-gradient(90deg, ${theme.color.brand_yarmill_6} 30%, ${theme.color.other_pink_8}, ${theme.color.brand_yarmill_6} 70%) right / 300% 100%;
  `};
`;
