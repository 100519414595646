import { MouseEventHandler, ReactElement, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Text } from '../text';
import { css, styled } from '../theme-provider';

const Layout = styled.div<{
  readonly href?: string;
  readonly to?: LinkProps['to'];
}>`
  display: grid;
  align-items: center;
  position: relative;
  text-decoration: none;

  :focus-visible {
    outline: none;
  }

  ${({ theme, onClick, href, to }) => css`
    grid-template-columns: ${theme.size.x25} 1fr;
    grid-column-gap: ${theme.size.x075};
    color: ${onClick || href || to ? theme.color.neutral_neutral_2 : theme.color.neutral_neutral_64};
    border-radius: ${theme.size.x075};
    padding: ${theme.size.x075};
    cursor: ${onClick || href || to ? 'pointer' : 'default'};

    svg {
      color: ${theme.color.neutral_neutral};
    }

    &:first-child {
      margin-bottom: ${theme.size.x1};

      &:after {
        content: " ";
        grid-column: 1 / -1;
        position: relative;
        bottom: -${theme.size.x15};
        height: ${theme.size.x0125};
        width: 100%;
        background-color: ${theme.color.neutral_neutral_dark};
      }
    }

    &:last-child {
      margin-top: ${theme.size.x1};

      &:before {
        content: " ";
        grid-column: 1 / -1;
        position: relative;
        top: -${theme.size.x15};
        height: ${theme.size.x0125};
        width: 100%;
        background-color: ${theme.color.neutral_neutral_dark};
      }
    }

    &:only-child {
      &:after, &:before {
        content: none;
      }
    }

    &.active {
      background-color: ${theme.color.neutral_neutral_darker};
    }
  `};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;


  ${({ theme }) => css`
    width: ${theme.size.x25};
    height: ${theme.size.x25};

    img, svg {
      width: ${theme.size.x25};
      height: ${theme.size.x25};
    }
  `};
`;

type HeaderPosition = 'top' | 'bottom';

interface DropdownHeaderProps {
  readonly isActive?: boolean;
  readonly icon?: ReactElement;
  readonly label: string;
  readonly tabIndex?: number;
  readonly onClick?: MouseEventHandler<HTMLDivElement>;
  readonly position?: HeaderPosition;
  readonly href?: string;
  readonly to?: LinkProps['to'];
  readonly closeDropdown?: () => void;
  readonly closeOnIconClick?: boolean;
  readonly ariaLabel?: string;
}

export const DropdownHeader = forwardRef<HTMLDivElement, DropdownHeaderProps>(
  function DropdownHeader(
    {
      icon,
      label,
      onClick,
      isActive,
      position = 'top',
      href,
      to,
      closeDropdown,
      closeOnIconClick,
      ariaLabel,
      ...otherProps
    },
    ref
  ) {
    return (
      <Layout
        as={to ? Link : href ? 'a' : 'div'}
        ref={onClick ? ref : undefined}
        onClick={onClick}
        role="option"
        className={isActive ? 'active' : undefined}
        href={href}
        to={to}
        {...(onClick || href || to ? otherProps : undefined)}
      >
        {icon && (
          <IconWrapper
            as={closeOnIconClick ? 'button' : undefined}
            type={closeOnIconClick ? 'button' : undefined}
            onClick={closeOnIconClick ? closeDropdown : undefined}
            aria-label={closeOnIconClick ? ariaLabel : undefined}
          >
            {icon}
          </IconWrapper>
        )}
        <Text appearance="_12M" as="span" wordBreak="break-word">
          {label}
        </Text>
      </Layout>
    );
  }
);
