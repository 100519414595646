import { styled } from '@yarmill/component-library';
import { CSSProperties } from 'react';
import { Toaster as SonnerToaster } from 'sonner';

const styles: CSSProperties = { '--width': `292px` } as CSSProperties;

const StyledSonnerToaster = styled(SonnerToaster)`
  [data-sonner-toast][data-y-position=bottom][data-x-position=center] {
    display: flex;
    justify-content: center;
  }

`;

export function Toaster() {
  return <StyledSonnerToaster offset={24} gap={8} style={styles} />;
}
