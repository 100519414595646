import { css, styled } from './theme-provider';

export const SIDE_MENU_WIDTH = 224 - 8; // 8px gap
export const DRAWER_WIDTH = 320 - 8; // 8px gap
export const DRAWER_CONTAINER_WIDTH = DRAWER_WIDTH + 16;

export const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  align-items: stretch;
  background-color: ${({ theme }) => theme.color.background_background_03};
`;

export const AppLayout = styled.div<{
  readonly $hasDrawer?: boolean;
  readonly $showSideMenu?: boolean;
  readonly $focusMode?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: grid;
  transition: grid 300ms ease;
  ${({ theme, $hasDrawer, $showSideMenu = true, $focusMode }) => css`
    grid-template-areas: ${$hasDrawer ? '"totem side-menu drawer canvas"' : '"totem side-menu canvas"'};
    ${
      $focusMode
        ? $hasDrawer
          ? css`grid-template-columns: 0 0 0 1fr`
          : css`grid-template-columns: 0 0 1fr`
        : $hasDrawer
          ? css`grid-template-columns: ${theme.size.x8} ${$showSideMenu ? `${SIDE_MENU_WIDTH}px` : 0} ${DRAWER_CONTAINER_WIDTH}px 1fr;`
          : css`grid-template-columns: ${theme.size.x8} ${$showSideMenu ? `${SIDE_MENU_WIDTH}px` : 0} 1fr;`
    };
  `};
`;

export const TotemLayout = styled.div`
  grid-area: totem;
  overflow: hidden;
  display: flex;
`;

export const SideMenuLayout = styled.div`
  grid-area: side-menu;
  display: flex;
  overflow: hidden;
  ${({ theme }) => css`
    padding: ${theme.size.x1} 0;
  `};
`;

export const DrawerLayout = styled.div`
  grid-area: drawer;
  display: flex;
  overflow: hidden;
  ${({ theme }) => css`
    padding: ${theme.size.x1} 0 ${theme.size.x1} ${theme.size.x1};
  `};
`;

export const CanvasLayout = styled.div`
  grid-area: canvas;
  display: flex;
  overflow: hidden;
  ${({ theme }) => css`
    padding: ${theme.size.x1};
  `};
`;
