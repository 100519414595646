import {
  FloatingNode,
  FloatingTree,
  Placement,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
} from '@floating-ui/react';
import { normalizeString } from '@yarmill/utils';
import {
  FocusEventHandler,
  ForwardedRef,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LinkProps } from 'react-router-dom';
import { Color, ThemeProvider } from '../theme-provider';
import { DropdownButton } from './dropdown-button';
import { DropdownContext } from './dropdown-context';
import { DropdownList } from './dropdown-list';
import { useEventEmitter } from './use-event-emitter';
import { useHandleSelect } from './use-handle-select';
import { useInputKeydownHandler } from './use-input-keydown-handler';

export interface DropdownOption<V> {
  readonly label?: string;
  readonly value?: V;
  readonly icon?: ReactElement;
  readonly color?: Color;
  readonly children?: DropdownOption<V>[];
  readonly onClick?: MouseEventHandler & KeyboardEventHandler;
  readonly isAdditional?: boolean;
  readonly isSelected?: boolean;
  readonly showPlusIcon?: boolean;
  readonly isHeader?: boolean;
  readonly to?: LinkProps['to'];
  readonly href?: string;
  readonly ariaLabel?: string;
}

type DropdownProviderProps<V> = PropsWithChildren<{
  readonly options: DropdownOption<V>[];
  readonly searchInputPlaceholder?: string;
  readonly multiSelect?: boolean;
  readonly keyboardShortcut?: string;
  readonly selectedValue?: V | V[];
  readonly handleSelect?: (value: V) => void;
  readonly searchable?: boolean;
  readonly label?: string;
  readonly placement?: Placement;
  readonly onFocus?: FocusEventHandler;
  readonly onMouseEnter?: MouseEventHandler;
  readonly disabled?: boolean;
  readonly id?: string;
  readonly selectLabel?: string;
  readonly selectAndCloseLabel?: string;
  readonly useOptionReferenceSize?: boolean;
  readonly overlayReferenceButton?: boolean;
}>;

function getSelectedIndex<V>(
  options: DropdownOption<V>[],
  selectedValue: V
): number | undefined {
  if (!selectedValue) {
    return undefined;
  }

  const selected = Array.isArray(selectedValue)
    ? selectedValue[0]
    : selectedValue;
  const optionIndex = options.findIndex(o => o.value === selected);

  if (optionIndex !== -1) {
    return optionIndex;
  }
  return undefined;
}

const DropdownComponent = forwardRef(function DropdownProvider<V>(
  {
    children,
    options,
    searchInputPlaceholder,
    keyboardShortcut,
    selectedValue,
    handleSelect: passedHandleSelect,
    searchable,
    multiSelect,
    placement,
    label,
    disabled,
    selectLabel,
    selectAndCloseLabel,
    useOptionReferenceSize,
    overlayReferenceButton,
    ...otherProps
  }: DropdownProviderProps<V>,
  forwardedRef: ForwardedRef<HTMLElement>
): ReactElement {
  const parentId = useFloatingParentNodeId();
  const nodeId = useFloatingNodeId();
  const item = useListItem();
  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const parent = useContext(DropdownContext);
  const isNested = parentId != null;
  const id = otherProps.id;

  const [isOpened, setIsOpened] = useState(false);
  const [hasFocusInside, setHasFocusInside] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [filterValue, setFilterValue] = useState<string>('');
  const virtualItemRef = useRef<HTMLElement | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    nodeId,
    open: isOpened,
    onOpenChange: setIsOpened,
    placement: isNested ? 'right-start' : (placement ?? 'bottom-start'),
    middleware: [
      flip(),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            // Minimum acceptable height is 50px.
            // `flip` will then take over.
            // maxHeight: `${Math.max(50, Math.min(availableHeight - 20, window.innerHeight / 2))}px`,
            maxHeight: `${Math.max(50, availableHeight - 20)}px`,
          });
        },
      }),
      offset(state => ({
        crossAxis: isNested
          ? state.placement.includes('end')
            ? 9
            : -9
          : overlayReferenceButton
            ? -9
            : 0,
        mainAxis: isNested
          ? 8
          : overlayReferenceButton
            ? -1 * (state.rects.reference.height + 9)
            : 4,
      })),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });

  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
    enabled: !disabled,
  });

  const filteredOptions = useMemo(
    () =>
      filterValue
        ? options.filter(o =>
            normalizeString(o.label?.toLowerCase() ?? '').includes(
              normalizeString(filterValue.toLowerCase())
            )
          )
        : options,
    [options, filterValue]
  );

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context, { bubbles: true });
  const handleSelect = useHandleSelect(setIsOpened, passedHandleSelect);

  const [onInputKeyDown, disableFocusOnHover] = useInputKeydownHandler(
    activeIndex,
    filteredOptions,
    isNested,
    multiSelect,
    handleSelect
  );

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex: multiSelect
      ? undefined
      : getSelectedIndex(filteredOptions, selectedValue),
    onNavigate: setActiveIndex,
    nested: isNested,
    virtual: searchable,
    virtualItemRef: searchable ? virtualItemRef : undefined,
    focusItemOnHover: !disableFocusOnHover,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [hover, click, role, dismiss, listNav]
  );

  const dropdownContext = useMemo(
    () => ({
      activeIndex,
      setActiveIndex,
      getItemProps,
      setHasFocusInside,
      isOpen: isOpened,
      selectedValue,
      handleSelect,
      multiSelect: multiSelect ?? false,
      setFilterValue,
      onInputKeyDown,
      filterValue,
      id,
      useOptionReferenceSize,
      setIsOpened,
      overlayReferenceButton,
    }),
    [
      activeIndex,
      getItemProps,
      isOpened,
      selectedValue,
      handleSelect,
      multiSelect,
      onInputKeyDown,
      filterValue,
      id,
      useOptionReferenceSize,
      overlayReferenceButton,
    ]
  );

  useEventEmitter(isOpened, setIsOpened);

  useEffect(() => {
    if (!isOpened) {
      setFilterValue('');
    }
  }, [isOpened]);

  return (
    <FloatingNode id={nodeId}>
      <DropdownButton
        ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
        type="button"
        disabled={disabled}
        $isNested={isNested}
        data-nested={isNested ? '' : undefined}
        $hasFocusInside={
          hasFocusInside || (isNested && parent.activeIndex === item.index)
        }
        tabIndex={!isNested ? 0 : parent.activeIndex === item.index ? 0 : -1}
        {...getReferenceProps(
          parent.getItemProps({
            ...otherProps,
            onFocus(event) {
              otherProps.onFocus?.(event);
              setHasFocusInside(false);
              parent.setHasFocusInside(true);
            },
          })
        )}
      >
        {children}
      </DropdownButton>
      <ThemeProvider theme="master" dark>
        <DropdownContext.Provider value={dropdownContext}>
          <DropdownList
            floatingContext={context}
            searchable={searchable}
            options={filteredOptions}
            floatingRefs={refs}
            floatingStyles={floatingStyles}
            getFloatingProps={getFloatingProps}
            label={label}
            showFooter={false}
            selectLabel={selectLabel}
            selectAndCloseLabel={selectAndCloseLabel}
            searchInputPlaceholder={searchInputPlaceholder}
            elementsRef={elementsRef}
            isNested={isNested}
          />
        </DropdownContext.Provider>
      </ThemeProvider>
    </FloatingNode>
  );
});

export const DropdownProvider = forwardRef(function DropdownProvider<V>(
  props: DropdownProviderProps<V>,
  forwardedRef: ForwardedRef<HTMLDivElement>
): ReactElement {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownComponent {...(props as any)} ref={forwardedRef} />
      </FloatingTree>
    );
  }

  return <DropdownComponent {...(props as any)} ref={forwardedRef} />;
});
