import { PropsWithChildren } from 'react';
import { SIDE_MENU_WIDTH } from './page-layout';
import { Text } from './text';
import { css, styled } from './theme-provider';

type SideMenuProps = PropsWithChildren<{
  readonly headline: string;
}>;

const Layout = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Headline = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${({ theme }) => theme.color.neutral_neutral};
    height: ${theme.size.x4};
    margin-top: ${theme.size.x1};
    margin-bottom: ${theme.size.x35};
  `};
`;

export const SideMenuContainer = styled.div`
  ${({ theme }) => css`
    padding-left: ${theme.size.x1};
    min-width: ${SIDE_MENU_WIDTH}px;
  `};
`;

export function SideMenu({ children, headline }: SideMenuProps) {
  return (
    <Layout>
      <Headline appearance="_12B" align="center" as="div">
        {headline}
      </Headline>
      {children}
    </Layout>
  );
}
