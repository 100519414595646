import { Cmd, Enter } from '@yarmill/icon-library';
import { Key, KeysCombination } from '../key';
import { Text } from '../text';
import { css, styled } from '../theme-provider';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${({ theme }) => css`
    border-bottom-left-radius: ${theme.size.x1};
    border-bottom-right-radius: ${theme.size.x1};
    padding: ${theme.size.x15} ${theme.size.x05} ${theme.size.x1};
    color: ${theme.color.neutral_neutral};

    :before {
      content: '';
      height: 1px;
      width: calc(100% + ${theme.size.x2});
      position: absolute;
      top: 0;
      left: -${theme.size.x1};
      background-color: ${theme.color.neutral_neutral_dark};
    }
  `};
`;

const Command = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => css`
    column-gap: ${theme.size.x05};
  `};
`;

interface FooterProps {
  readonly selectLabel: string;
  readonly selectAndCloseLabel: string;
}

export function Footer({ selectLabel, selectAndCloseLabel }: FooterProps) {
  return (
    <Layout>
      <Command>
        <Text appearance="_10M">{selectLabel}</Text>
        <Key k={<Enter />} />
      </Command>
      <Command>
        <Text appearance="_10M">{selectAndCloseLabel}</Text>
        <KeysCombination>
          <Key k={<Cmd />} />
          <Key k={<Enter />} />
        </KeysCombination>
      </Command>
    </Layout>
  );
}
