import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react';
import { ForwardedRef, forwardRef, useCallback, useContext } from 'react';
import { DropdownContext } from './dropdown-context';
import { DropdownHeader } from './dropdown-header';
import { DropdownListItem } from './dropdown-list-item';
import { DropdownOption, DropdownProvider } from './dropdown-provider';

export const Option = forwardRef(function Option<V>(
  {
    children,
    value,
    label,
    icon,
    onClick,
    isAdditional,
    showPlusIcon,
    isHeader,
    ariaLabel,
    ...otherProps
  }: DropdownOption<V>,
  forwardedRef: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const {
    activeIndex,
    getItemProps,
    handleSelect,
    selectedValue,
    setHasFocusInside,
    multiSelect,
    setIsOpened,
    overlayReferenceButton,
  } = useContext(DropdownContext);

  const { ref, index } = useListItem({
    label,
  });
  const tree = useFloatingTree();
  const mergedRef = useMergeRefs([ref, forwardedRef]);

  const isActive = activeIndex === index;
  const isSelected = Boolean(
    ((Array.isArray(selectedValue)
      ? selectedValue.includes(value)
      : selectedValue === value) &&
      value !== undefined) ||
      (isAdditional && otherProps.isSelected)
  );
  const hasChildren = (children?.length ?? 0) > 0;

  const closeDropdown = useCallback(() => setIsOpened(false), [setIsOpened]);

  if (isHeader) {
    return (
      <DropdownHeader
        isActive={
          onClick || otherProps.href || otherProps.to ? isActive : undefined
        }
        icon={icon}
        label={String(label)}
        onClick={onClick}
        closeDropdown={closeDropdown}
        closeOnIconClick={
          !onClick &&
          !otherProps.href &&
          !otherProps.to &&
          overlayReferenceButton
        }
        ariaLabel={ariaLabel}
        ref={mergedRef}
        tabIndex={isActive ? 0 : -1}
        position={index === 0 ? 'top' : 'bottom'}
        {...(onClick || otherProps.to || otherProps.href
          ? getItemProps({
              ...otherProps,
              onClick: e => {
                onClick?.(e);
                closeDropdown();
              },
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  onClick?.(e);
                  closeDropdown();
                }
              },
              onFocus() {
                setHasFocusInside(true);
              },
            })
          : undefined)}
      />
    );
  }

  const option = (
    <DropdownListItem
      isActive={isActive}
      isSelected={isSelected}
      label={String(label)}
      icon={icon}
      tabIndex={isActive ? 0 : -1}
      showCheckbox={multiSelect && !hasChildren}
      hasSubmenu={hasChildren}
      ref={mergedRef}
      index={index}
      value={value}
      handleSelect={handleSelect}
      showPlusIcon={showPlusIcon}
      {...(!hasChildren
        ? getItemProps({
            ...otherProps,
            onClick: e => {
              onClick?.(e);
              handleSelect(index, value);
              tree?.events.emit('click');
            },
            onKeyDown: e => {
              if (e.key === 'Enter') {
                const keepOpen = multiSelect && !e.metaKey && !e.ctrlKey;
                handleSelect(index, value, keepOpen);
              }
            },
            onFocus() {
              setHasFocusInside(true);
            },
          })
        : {})}
    />
  );

  if (children?.length) {
    return (
      <DropdownProvider
        ref={mergedRef as any}
        options={children}
        selectedValue={selectedValue}
        handleSelect={value => handleSelect(index, value)}
        {...otherProps}
      >
        {option}
      </DropdownProvider>
    );
  } else {
    return option;
  }
});
