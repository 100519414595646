import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

   @font-face {
    font-family: 'Geist';
    font-style: normal;
    src: url(/fonts/geist/GeistVariableVF.woff2) format('woff2');
  }

   @font-face {
     font-family: 'GeistMono';
     font-style: normal;
     src: url(/fonts/geist-mono/GeistMonoVariableVF.woff2) format('woff2');
   }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

   html, body {
     font-synthesis-weight: none;
   }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  #yarmill-diary-app {
    width: 100%;
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100% !important;
    position: fixed;
    background-color: #edf0f5;

    @media print {
      background-color: #ffffff;
      width: unset;
      height: unset !important;
      position: unset;
    }
  }

  @media print {
    background-color: #fff;
    * {
      box-shadow: none !important;
    }
  }

	b, strong {
		font-weight: bold;
	}
`;

const StyledIntegratorRoot = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  * {
    box-sizing: border-box;
  }

  @media print {
    height: unset;
  }
`;

export function IntegratorRoot(props: React.PropsWithChildren): JSX.Element {
  return (
    <StyledIntegratorRoot>
      <GlobalStyle />
      {props.children}
    </StyledIntegratorRoot>
  );
}
