import { MouseEventHandler, PropsWithChildren, ReactElement } from 'react';
import { css, styled } from './theme-provider';

type TotemProps = PropsWithChildren<{
  readonly logo: ReactElement;
  readonly avatar: ReactElement;
  readonly handleAvatarClick?: MouseEventHandler;
  readonly avatarAriaLabel?: string;
  readonly settingsButton?: ReactElement;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  position: relative;
  background-color: ${({ theme }) => theme.color.background_background_04};
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  ${({ theme }) => css`
    padding: ${theme.size.x2} ${theme.size.x2} ${theme.size.x35};
  `};
`;

const MiddleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => css`
    row-gap: ${theme.size.x35};
  `};
`;

const BottomContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => css`
    row-gap: ${theme.size.x35};
    padding: ${theme.size.x25} 0;
    border-top-right-radius: ${theme.size.x1};
    background-color: ${({ theme }) => theme.color.background_background_04};
  `};
`;

export const TotemAvatarWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease, border 300ms ease;
  cursor: pointer;

  ${({ theme }) => css`
    padding: 0;
    background-color: ${theme.color.background_background_03};
    width: ${theme.size.x4};
    height: ${theme.size.x4};
    border: ${theme.size.x025} solid ${theme.color.background_background_03};

    :hover {
      border-color: ${theme.color.background_background_05};
    }
  `};
`;

export const LogoWrapper = styled(TotemAvatarWrapper)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x1};
  `};
`;

export function Totem({
  logo,
  avatar,
  children,
  settingsButton,
}: TotemProps): ReactElement {
  return (
    <Layout>
      <TopContainer>{logo}</TopContainer>
      <MiddleContainer>{children}</MiddleContainer>
      <BottomContainer>
        {settingsButton}
        {avatar}
      </BottomContainer>
    </Layout>
  );
}
