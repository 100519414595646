import { ErrorBoundary } from '@yarmill/components';
import MarkdownComponent, { MarkdownToJSX } from 'markdown-to-jsx';
import { ComponentType, Fragment, createElement, useMemo } from 'react';
import { ExternalLink } from './link';
import { Text } from './text';
import { css, styled } from './theme-provider';

interface MarkdownProps {
  readonly text: string | undefined;
  readonly dangerouslyParseRawHTML?: boolean;
  readonly overrides?: MarkdownToJSX.Overrides;
}

export function createTagOverride<P>(
  component: ComponentType<P>,
  props?: P
): MarkdownToJSX.Override {
  return props ? { component, props } : { component };
}

const allowedHTMLTags = [
  'a',
  'b',
  'br',
  'div',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'i',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'sub',
  'sup',
  'table',
  'tbody',
  'thead',
  'td',
  'th',
  'tr',
  'u',
  'ul',
  'pre',
  'code',
];

const Code = styled(Text)`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.x1};
    background-color: ${theme.color.background_background_03};
    padding: ${theme.size.x1};
    margin: ${theme.size.x1} 0;
    display: block;
    white-space: pre-wrap;
  `};
`;

const Block = styled(Text)`
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  unicode-bidi: isolate;

  &:last-child:not(ul &) {
    margin-block-end: 0;
  }
`;

const List = styled(Block)`
  list-style-position: outside;
  margin-block-start: 1em;
  padding-inline-start: 2em;
`;
const defaultOverrides = {
  h1: createTagOverride(Text, { appearance: '_24B', as: 'h1' }),
  h2: createTagOverride(Text, { appearance: '_18B', as: 'h2' }),
  h3: createTagOverride(Text, { appearance: '_16B', as: 'h3' }),
  h4: createTagOverride(Text, { appearance: '_14B', as: 'h4' }),
  h5: createTagOverride(Text, { appearance: '_12B', as: 'h5' }),
  h6: createTagOverride(Text, { appearance: '_10B', as: 'h6' }),
  p: createTagOverride(Block, { appearance: '_14MText', as: 'p' }),
  span: createTagOverride(Text, { appearance: '_14MText', as: 'span' }),
  div: createTagOverride(Text, { appearance: '_14MText', as: 'div' }),
  strong: createTagOverride(Text, { appearance: '_14BText', as: 'strong' }),
  ol: createTagOverride(List, { appearance: '_14MText', as: 'ol' }),
  ul: createTagOverride(List, { appearance: '_14MText', as: 'ul' }),
  li: createTagOverride(Text, { appearance: '_14MText', as: 'li' }),
  a: createTagOverride(ExternalLink),
  b: createTagOverride(Text, { appearance: '_14MText', as: 'b', bold: true }),
  br: createTagOverride(Text, { appearance: '_14MText', as: 'br' }),
  em: createTagOverride(Text, { appearance: '_14MText', as: 'em' }),
  i: createTagOverride(Text, { appearance: '_14MText', as: 'i' }),
  sub: createTagOverride(Text, { appearance: '_14MText', as: 'sub' }),
  sup: createTagOverride(Text, { appearance: '_14MText', as: 'sup' }),
  table: createTagOverride(Text, { appearance: '_14MText', as: 'table' }),
  tbody: createTagOverride(Text, { appearance: '_14MText', as: 'tbody' }),
  thead: createTagOverride(Text, { appearance: '_14MText', as: 'thead' }),
  td: createTagOverride(Text, { appearance: '_14MText', as: 'td' }),
  th: createTagOverride(Text, { appearance: '_14MText', as: 'th' }),
  tr: createTagOverride(Text, { appearance: '_14MText', as: 'tr' }),
  u: createTagOverride(Text, { appearance: '_14MText', as: 'u' }),
  code: createTagOverride(Code, { appearance: '_14MText', as: 'code' }),
  pre: createTagOverride(Text, { appearance: '_14MText', as: 'pre' }),
};

export function Markdown({
  text,
  dangerouslyParseRawHTML,
  overrides,
}: MarkdownProps) {
  const mdOptions: MarkdownToJSX.Options = useMemo(
    () => ({
      disableParsingRawHTML: dangerouslyParseRawHTML ? false : true,
      overrides: {
        ...defaultOverrides,
        ...overrides,
      },
      createElement(type, _elementProps, children) {
        if (typeof type === 'string' && !allowedHTMLTags.includes(type)) {
          return <>{children}</>;
        }
        // biome-ignore lint/suspicious/noExplicitAny: there's no better type for arguments, literally could be anything
        // biome-ignore lint/correctness/noUndeclaredVariables: arguments cannot be undeclared in non-arrow functions
        // biome-ignore lint/style/noArguments: I'd rather pass type, elementProps, and children as normal arguments to React.createElement, but somehow that breaks tables somewhere deep in markdown-to-jsx. ¯\_(ツ)_/¯
        return createElement.apply(this, arguments as any);
      },
      wrapper: Fragment,
      forceWrapper: true,
    }),
    [dangerouslyParseRawHTML, overrides]
  );

  return (
    <ErrorBoundary fallback={<div>{text}</div>}>
      <MarkdownComponent options={mdOptions}>{text ?? ''}</MarkdownComponent>
    </ErrorBoundary>
  );
}
