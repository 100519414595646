import {
  AsyncStatus,
  RootStore as iRootStore,
  YollandaService,
} from '@yarmill/types';
import {
  Logger,
  MemoryStore,
  RequestsStore,
  getQueryParam,
} from '@yarmill/utils';
import { FocusedDayService } from '@yarmill/utils';
import { AxiosError } from 'axios';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ActivityItemsStore } from '../../activities/mobx/activity-items-store';
import { AnnouncementsStore } from '../../announcement/mobx/announcements-store';
import { AuthStore } from '../../auth/mobx/auth-store';
import { CgmStore } from '../../cgm/mobx/cgm-store';
import { ConfigStore } from '../../config/config-store';
import { InitialConfigError } from '../../error/initial-config-error';
import { ExportService } from '../../export/mobx/export-service';
import { FileDownloadService } from '../../fileupload/mobx/file-download-service';
import { FileUploadService } from '../../fileupload/mobx/file-upload-service';
import { GoogleCalendarService } from '../../google-calendar/mobx/google-calendar-service';
import { GoogleMapsService } from '../../google-map/mobx/google-maps-service';
import { GroupsStore } from '../../groups/mobx/groups-store';
import { HeartRateZonesManagerStore } from '../../heart-rate-zones/mobx/heart-rate-zones-manager-store';
import { ImageService } from '../../image-preview/mobx/image-service';
import { IntlStore } from '../../intl/mobx/intl-store';
import { LayerManagerService } from '../../layer-manager/mobx/layer-manager-service';
import { ModulesRegistryService } from '../../modules/mobx/modules-registry-service';
import { ModulesStore } from '../../modules/mobx/modules-store';
import { NavbarStore } from '../../navbar/mobx/navbar-store';
import { handleRedirectAction } from '../../page/utils';
import { HistoryService } from '../../routes/mobx/history-service';
import { SeasonsStore } from '../../seasons/mobx/seasons-store';
import { SettingsService } from '../../settings/mobx/settings-service';
import { SidebarStore } from '../../sidebar/mobx/sidebar-store';
import { CurrentUserStore } from '../../users/mobx/current-user-store';
import { UsersStore } from '../../users/mobx/users-store';
import { InactiveUserService } from '../../utils/mobx/inactive-user-service';
import { VideoService } from '../../video-player/mobx/video-service';
import { VideoSelectorService } from '../../videoselector/mobx/video-selector-service';
import { WorkoutDetailStore } from '../../workouts/mobx/workout-detail-store';
import { REDIRECT_ACTION_PARAM } from '../utils';

export class RootStore implements iRootStore {
  readonly logger: Logger;
  readonly activityItemsStore: ActivityItemsStore;
  readonly announcementsStore: AnnouncementsStore;
  readonly configStore: ConfigStore;
  readonly requestsStore: RequestsStore;
  readonly seasonsStore: SeasonsStore;
  readonly modulesStore: ModulesStore;
  readonly currentUserStore: CurrentUserStore;
  readonly usersStore: UsersStore;
  readonly workoutDetailStore: WorkoutDetailStore;
  readonly groupsStore: GroupsStore;
  readonly cgmStore: CgmStore;
  readonly modulesRegistryService: ModulesRegistryService;
  readonly authStore: AuthStore;
  readonly memoryStore: MemoryStore;
  readonly intlStore: IntlStore;
  readonly navbarStore: NavbarStore;
  readonly heartRateZonesManagerStore: HeartRateZonesManagerStore;
  readonly sidebarStore: SidebarStore;
  readonly historyService: HistoryService;
  readonly fileUploadService: FileUploadService;
  readonly videoService: VideoService;
  readonly imageService: ImageService;
  readonly focusedDayService: FocusedDayService;
  readonly inactiveUserService: InactiveUserService;
  readonly exportService: ExportService;
  readonly settingsService: SettingsService;
  readonly googleCalendarService: GoogleCalendarService;
  readonly layerManagerService: LayerManagerService;
  readonly videoSelectorService: VideoSelectorService;
  readonly fileDownloadService: FileDownloadService;
  yollandaService: YollandaService | undefined = undefined;
  readonly googleMapsService: GoogleMapsService;

  @observable
  status: AsyncStatus = AsyncStatus.idle;

  constructor(logger: Logger) {
    makeObservable(this);
    this.logger = logger;
    this.logger.setRootStore(this);

    this.authStore = new AuthStore(this);

    this.historyService = new HistoryService();
    this.fileUploadService = new FileUploadService(this);
    this.videoService = new VideoService(this);
    this.imageService = new ImageService();
    this.focusedDayService = new FocusedDayService();
    this.exportService = new ExportService(this);
    this.settingsService = new SettingsService(this);
    this.googleCalendarService = new GoogleCalendarService(this);
    this.layerManagerService = new LayerManagerService();
    this.videoSelectorService = new VideoSelectorService();
    this.fileDownloadService = new FileDownloadService(this);
    this.googleMapsService = new GoogleMapsService(this);

    this.sidebarStore = new SidebarStore();
    this.memoryStore = new MemoryStore();
    this.intlStore = new IntlStore(this);

    this.navbarStore = new NavbarStore(this);
    this.requestsStore = new RequestsStore();
    this.activityItemsStore = new ActivityItemsStore(this);
    this.configStore = new ConfigStore(this);
    this.currentUserStore = new CurrentUserStore(this);
    this.modulesStore = new ModulesStore(this);
    this.seasonsStore = new SeasonsStore(this);
    this.usersStore = new UsersStore(this);
    this.groupsStore = new GroupsStore(this);
    this.workoutDetailStore = new WorkoutDetailStore(this);
    this.cgmStore = new CgmStore(this);
    this.announcementsStore = new AnnouncementsStore(this);
    this.heartRateZonesManagerStore = new HeartRateZonesManagerStore(this);

    this.modulesRegistryService = new ModulesRegistryService(this);

    this.inactiveUserService = new InactiveUserService(this);

    const tokenInUrl = getQueryParam('token');
    if (tokenInUrl) {
      this.loadRegistrationConfig();
    } else {
      if (!this.authStore.isLoggedIn) {
        this.loadLoginScreenConfiguration();
      } else {
        this.loadInitialConfiguration();
      }
    }
  }

  @computed
  get isReady(): boolean {
    return this.status === AsyncStatus.resolved;
  }

  async loadInitialConfiguration(): Promise<void> {
    if (!this.authStore.isLoggedIn) {
      return;
    }

    try {
      runInAction(() => {
        this.status = AsyncStatus.pending;
      });

      await runInAction(async () => {
        await Promise.all([
          this.intlStore.load(),
          this.configStore.loadConfig(),
          this.currentUserStore.loadCurrentUser(),
          this.seasonsStore.loadSeasons(),
          this.usersStore.loadUsers(),
          this.groupsStore.loadGroups(),
        ]);
      });

      runInAction(() => {
        handleRedirectAction(
          this,
          this.historyService.searchParams.get(REDIRECT_ACTION_PARAM)
        );

        this.status = AsyncStatus.resolved;
      });

      await this.authStore.regenerateAuthToken();
    } catch (e: unknown) {
      const isTermsError =
        e instanceof InitialConfigError &&
        e.originalError instanceof AxiosError &&
        e.originalError.response?.status === 401;

      if (!isTermsError) {
        this.logger.error(
          typeof e === 'object' && e && 'message' in e ? e.message : e
        );
      }
      runInAction(() => {
        this.status = AsyncStatus.rejected;
      });
    }
  }

  async loadRegistrationConfig(): Promise<void> {
    try {
      this.status = AsyncStatus.pending;

      await Promise.all([
        this.intlStore.load(),
        this.configStore.loadConfig(),
        this.currentUserStore.loadCurrentUserWithoutPermissions(),
      ]);

      this.status = AsyncStatus.resolved;
    } catch (e: unknown) {
      this.logger.error(
        typeof e === 'object' && e && 'message' in e ? e.message : e
      );
      this.status = AsyncStatus.resolved;
    }
  }
  async loadLoginScreenConfiguration(): Promise<void> {
    try {
      this.status = AsyncStatus.pending;

      await Promise.all([this.intlStore.load()]);

      runInAction(() => {
        handleRedirectAction(
          this,
          this.historyService.searchParams.get(REDIRECT_ACTION_PARAM)
        );
        this.status = AsyncStatus.resolved;
      });
    } catch (e: unknown) {
      this.logger.error(
        typeof e === 'object' && e && 'message' in e ? e.message : e
      );
      this.status = AsyncStatus.resolved;
    }
  }
}
